<template>
    <div v-if="this.loaded">
        <main class="dashboard" v-if="loggedIn">
            <div class="logo">
                Service Charge <br />Calculator
            </div>

            <div class="utilBar">
                <Utils />
            </div>

            <div class="nav">
                <nav>
                    <ul>
                        <li v-for="(route, idx) in routes" class="w-icon" :key="idx">
                            <a v-if="showNavItem(route, this)" href="" @click.prevent="this.$router.push(route.path)">
                                <span class="material-symbols-outlined">{{ route.icon }}</span>
                                <span>
                                    {{ route.name }}
                                </span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>

            <div class="content">
                <router-view />
            </div>
            <LoaderOverlay :isActive="isActive" />

        </main>
        <main :class="[{ 'login': !loggedIn }]" v-if="!loggedIn">
            <Authentication />
        </main>
    </div>
    <div v-else>
        <main class="loading">
            <LoaderOverlay :isActive="!this.loaded" />
        </main>
    </div>
    <Notice :msg="msg" :status="status" :visible="visible" />
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { globalStore } from "../stores/global.js";
import { scStore } from "../stores/servicecharge.js";
import { noticeStore } from "../stores/notice.js";
import { loaderStore } from "../stores/loader.js";

import routes from '../../js/src/routes.js';

import Authentication from '../views/Authentication.vue';

import Notice from '../components/Notice.vue';
import Utils from '../components/Utils.vue';
import Modal from '../components/Modal.vue';
import LoaderOverlay from '../components/LoaderOverlay.vue';

export default {
    name: "ServiceChargeApp",
    components: {
        Utils,
        Notice,
        Authentication,
        LoaderOverlay
    },
    data() {
        return {
            routes: routes,
            componentLoaded: false
        };
    },
    mounted() {
        this.loadGlobalData();
    },
    computed: {
        ...mapState(globalStore, {
            loaded: store => store.loaded,
            loggedIn: store => store.isLoggedIn,
            restaurantSet: store => store.isRestaurantSet,
            startDate: store => store.currentPayPeriod.start,
            endDate: store => store.currentPayPeriod.end,
        }),
        ...mapState(scStore, {
            dataLoaded: store => store.loaded
        }),
        ...mapState(loaderStore, {
            isActive: store => store.isActive
        }),
        ...mapState(noticeStore, {
            msg: store => store.msg,
            visible: store => store.visible,
            status: store => store.status,
        }),
    },
    methods: {
        ...mapActions(globalStore, ['loadGlobalData']),
        ...mapActions(scStore, ['loadData']),
        showNavItem: function (route, scope) {
            let shouldShow = true;

            if ('relies_on' in route === false) {
                if ('hide_in_nav' in route === true) {
                    return false;
                }

                return true;
            }
            
            if (!this.restaurantSet) {
                return false;
            }

            return shouldShow;
        }
    }
};
</script>
