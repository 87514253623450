const routes = {
    'dashboard' : {
        'path' : '/dashboard/',
        'name' : 'Dashboard',
        'icon' : 'space_dashboard',
        'relies_on' : 'currentRestaurant'
    },
    // 'employees' : {
    //     'path' : '/employees/',
    //     'name' : 'Employees',
    //     'icon' : 'group',
    //     'relies_on' : 'currentRestaurant'
    // },
    'all-reports' : {
        'path' : '/all-reports/',
        'name' : 'Reports',
        'icon' : 'draft',
        'relies_on' : 'currentRestaurant'
    },
    'report' : {
        'path' : '/report/:id/',
        'name' : 'Report',
        'icon' : 'draft',
        'hide_in_nav' : true
    },
    // 'settings' : {
    //     'path' : '/settings/',
    //     'name' : 'Settings',
    //     'icon' : 'settings',
    //     'relies_on' : 'currentRestaurant'
    // },
    'profile' : {
        'path' : '/profile/',
        'name' : 'Profile',
        'hide_in_nav' : true
    },
    'restaurant' :{
        'path' : '/restaurant/:id/',
        'name' : 'Restaurant',
        'hide_in_nav' : true
    },
    'authorize' :{
        'path' : '/authorize/',
        'name' : 'Authorize',
        'hide_in_nav' : true
    }
}

export default routes;